import React, {useEffect, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl } from '@pbe/react-yandex-maps';

import '../../../components/itemCard.css';
import {useNavigate} from "react-router-dom";
import DetectorsService from "../../../api/detectorsService";
import {getCoordsByAzimuth} from "../../../utils/mapHelpers";
import config from "../../../config.json";

const TargetsPoints = ({ detectorId, stationPosition, setTarget }) => {
    const [targets, setTargets] = useState(null);
    const [selectedTargetIndex, setSelectedTargetIndex] = useState();

    const timeOutId = useRef([]);

    let navigate = useNavigate();

    const detectorIdRef = useRef('');
    const stationPositionRef = useRef(null);


    useEffect(()=>{
        return ()=>{
            clearTimeout(timeOutId.current)
        }
    },[])
    useEffect(() => {
        if(targets && selectedTargetIndex !== undefined && targets[selectedTargetIndex]) setTarget(targets[selectedTargetIndex])
        else setTarget(undefined);
    }, [selectedTargetIndex, targets])

    useEffect(()=>{
        console.log('detectorId', detectorId)
        if(!detectorIdRef.current) {
            detectorIdRef.current = detectorId
            getCurrentDetectorTargets()
        }else{
            detectorIdRef.current = detectorId
        }

    },[detectorId])
    useEffect(() => {
        stationPositionRef.current = stationPosition;
    }, [stationPosition])
    useEffect(() => {
        console.log('targets', targets);
    }, [targets])

    const getCurrentDetectorTargetsRequest = async ( detectorNumber) => {
        const res = await DetectorsService.getTargets(navigate, detectorNumber)

        if (res && res.item && res.item.targets) {
            console.log('res.item.targets', res.item.targets);
            const targetsWithCoords = res.item.targets.map((target) => {
                const coords = getCoordsByAzimuth(target.azimuth, target.distance, stationPositionRef.current);
                return { ...target, ...coords };
            })

            setTargets(targetsWithCoords);
        }
    }

    const onTargetClick = (i) => {
        setSelectedTargetIndex(i);
    }

    const getCurrentDetectorTargets = async ()=> {
        if(detectorIdRef.current) timeOutId.current = setTimeout(async () => {
            await getCurrentDetectorTargetsRequest(detectorIdRef.current)
            getCurrentDetectorTargets(detectorIdRef.current)
        }, 1000)
    }
    return <>
    {/*    {position && <Placemark*/}
    {/*   // properties={{ iconContent: i !== 0 ? `<div class='iconContent'>${getDistance(i)}км</div>` : '' }}*/}
    {/*    modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}*/}
    {/*    options={{*/}
    {/*        iconLayout: 'default#imageWithContent',*/}
    {/*        // Своё изображение иконки метки.*/}
    {/*        iconImageHref: config.serverPath + "/img/bullseye.svg",*/}
    {/*        iconImageSize: [30, 30],*/}
    {/*        iconImageOffset: [-15, -15],*/}
    {/*    }}*/}
    {/*    geometry={[position.lat, position.lon]}*/}
    {/*/>}*/}

        {targets && targets.map((point, i) => <Placemark
            key={ "point" + i }
            properties={{ iconContent: `<div class='iconContent'>${point.distance/1000}км</div>`}}
            modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
            onClick={() => onTargetClick(i)}
            options={{
                iconLayout: 'default#imageWithContent',
                iconImageHref: i === selectedTargetIndex ? config.serverPath + "/img/crosshair2_red.svg" :  config.serverPath + "/img/crosshair.svg",
                iconImageSize: [30, 30],
                iconImageOffset: [-15, -15],
            }}
            geometry={[point.lat, point.lon]}
        /> )}
        </>
}
export default TargetsPoints
