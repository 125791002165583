import React, {useEffect, useMemo, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl, Circle } from '@pbe/react-yandex-maps';

import './itemCard.css';
import PointDetectorEditModalForm from "./pointDetectorEditModalForm";
import ControlsDetector from "./controls";
import config from "../../../config.json";
import TargetsPoints from "./targetsPoints";
import CurrentPosition from "../../../components/Map/currentPosition";


const DetectorMap = ({ detector, setDetector}) => {
    const [position, setPosition] = useState(null)
    const [targetPoint, setTarget] = useState(null)

    const [addPoint, setAddPoint] = useState(false)
    const [indexSelectedPoint, setIndexSelectedPoint] = useState()
    const [editMode, onChangeEditMode] = useState(null)

    useEffect(() => {
        if(detector && detector.position && detector.position.lat) setPosition(detector.position)
    }, [detector])



    const onChangePoint = (values) => {
        if(editMode) {
            setPosition({...values, lat: parseFloat(values.lat), lon: parseFloat(values.lon)})
        }
    }


    const getDistance = (i) => {
        //return getDistanceFromLatLonInKm(points[i], points[i-1])
        return 0;
    }

    const onMapClick = (e) => {
        //TODO edit mode сделать по кнопке на карте как в миссии
        if(editMode) {
            const coords = e.get('coords')
            setPosition({lat: coords[0], lon: coords[1], alt: 0})
        }
    }

    return <div>
        <ControlsDetector detector={detector} onChangeEditMode={onChangeEditMode} position={position} editMode={editMode} setDetector={setDetector}>
            <YMaps>
                {position && <Map
                    onClick={onMapClick}
                    options={{dragCursor: "arrow", fullscreenZIndex: 50,}}
                    width={'100%'}
                    height={500}
                    defaultState={{ center: position && position.lat ? [position.lat, position.lon] : [49.138505, 32.697259] , zoom: 12 }}
                >
                    {position && <>
                            <Placemark
                                // properties={{ iconContent: i !== 0 ? `<div class='iconContent'>${getDistance(i)}км</div>` : '' }}
                                modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                                onClick={() => onPointClick()}
                                options={{
                                    iconLayout: 'default#imageWithContent',
                                    // Своё изображение иконки метки.
                                    iconImageHref: `${config.serverPath}/img/bullseye_green.svg`,
                                    iconImageSize: [30, 30],
                                    iconImageOffset: [-15, -15],
                                }}
                                geometry={[position.lat, position.lon]}
                            />
                            <TargetsPoints detectorId={detector?.number} stationPosition={position} setTarget={setTarget} />
                            <Circle
                                geometry={[[position.lat, position.lon], 5000]}
                                options={{
                                    draggable: false,
                                    fillColor: "#228B2277",
                                    strokeColor: "#006400",
                                    strokeOpacity: 0.8,
                                    strokeWidth: 3,
                                }}
                            />
                        </>
                    }
                    {false && detector && detector.drone_number !== undefined && <CurrentPosition drone_number={detector.drone_number} targetPoint={targetPoint} />}
                    <FullscreenControl />
                    <TypeSelector options={{ float: "right" }} />
                    <RulerControl options={{ float: "right" }} />
                </Map>}
            </YMaps>
        </ControlsDetector>

    </div>
}
export default DetectorMap
