import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import DronesService from "../../../api/dronesService";
import {useNavigate} from "react-router-dom";
import {showError} from "../../../redux/errorSlice";
import {useDispatch} from "react-redux";
import DetectorsService from "../../../api/detectorsService";
import TextCustomInput from "../../../components/form/textInput";
import Select from "../../../components/form/select";
import PointDetectorEditModalForm from "./pointDetectorEditModalForm";

const typesOptions = [
    {name: "Тип"},
    {value: "video", name: "Видео"},
    {value: "radio", name: "Радио"},
]

const ControlsDetector = ({ children, detector, editMode, onChangeEditMode, position, setDetector }) => {
    const [dronesOptions, setDronesOptions] = useState([])
    const [detectorsOptions, setDetectorsOptions] = useState([]);
    const [detectorNumber, setDetectorNumber] = useState("");
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState("Новый детектор");
    const [selectedType, setSelectedType] = useState("");
    const [selectedDrone, setSelectedDrone] = useState();
    const [selectedDetector, setSelectedDetector] = useState();
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const onEdit = () => onChangeEditMode(true)

    const onCancel = () => {
        onChangeEditMode(false)
    }
    const getDroneName = () => {
        const option = dronesOptions.find((item) => item.value === selectedDrone)
        if (option?.value) return option?.name
    }
    const getConnectedDetector = () => {
        const option = detectorsOptions.find((item) => item.value === selectedDetector)
        if (option?.value) return option?.name
    }


    const onChangeDrone = (value) => setSelectedDrone(value)
    const onChangeType = (value) => setSelectedType(value)
    const onChangeDetector = (value) => setSelectedDetector(value)
    const onChangeDetectorNumber = (value) => setDetectorNumber(value)


    useEffect(()=>{
        if (detector) {
            setSelectedDrone(detector.drone_number)
            setSelectedType(detector.type)
            setTitle(detector.name)
            setDetectorNumber(detector.number)
        }
    },[detector])

    useEffect(()=>{
        getDrones()
        getDetectors()
    },[])

    // useEffect(()=>{
    //     if((!mission || !mission.droneId) && dronesOptions && dronesOptions[0]) setSelectedDrone(dronesOptions[0].value)
    // },[dronesOptions])
    const onBack = () => navigate(-1)
    const getDrones = async () =>{
        const res = await DronesService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            const options = res.items.map((item) => ({
                value: item.number,
                name: item.name
            }))
            options.unshift({name: "-устройство-"})
            setDronesOptions(options);
        }
    }
    const onCloseChangePoint = () => {
        setShow(false)
    }
    const openModal = () => {
        setShow(true)
    }

    const getDetectors = async () =>{
        const res = await DetectorsService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            const options = res.items.map((item) => ({
                value: item._id,
                name: item.name
            }));
            options.unshift({name: "-радар-"});
            setDetectorsOptions(options);
        }
    }
    return <div>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
            gap: 10,
        }}>
            <>
                <Button variant="secondary" onClick={onBack}>Назад</Button>
                <div style={{lineHeight:"37px"}}>{title}</div>
                <Button variant="secondary" onClick={openModal} style={{marginLeft: 'auto'}}>Редактировать</Button>
            </>
        </div>
        {children}
        <PointDetectorEditModalForm
            point={ position }
            //onSubmit={onChangePoint} TODO сделать change point
            oldValues={detector}
            onClose={onCloseChangePoint}
            editMode={editMode}
            setShow={setShow}
            show={show}
        />
    </div>
}
export default ControlsDetector
